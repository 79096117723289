import { createStore } from "vuex";
import { auth } from "./auth.module";
import { common } from "./common.module";

export default createStore({
	modules: {
		auth,
		common,
	},
	state: {
		classRoomTimeSelectStep: "00:15",
		usersTags: [],
		isShowContactBookView: false,
		isloading: true,
		LeftBarContentShow: false,
		searchTerm: "",
		classRoomTabActiveName: "first",
		homeWorkFilterCondition: {},
		classRoomCenterActiveMode: false,
		selectTagId: "All",
		isSelectKnowledge: false,
		selectGPTPage: "",
		isMessageShow: false,
		isOpenCampLineResponse: false,
		CreateGroupName: null,
		fullWidth: 0,
		hub: {
			connection: {},
			HubConnId: "",
		},
		targetGroupId: "",
		askCourse: false,
		askCourseId: null,
		isTalk: false,
		default: {
			video: "https://www.youtube.com/embed/Hzp16b_RQIQ",
			cover:
				"https://images.unsplash.com/photo-1524650359799-842906ca1c06?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
			avatar:
				"https://img.ttshow.tw/images/celebrity/%E9%A6%AC%E5%8F%94%E5%8F%94.jpg",
		},
		searchData: {
			instrument: "",
			minPrice: 0,
			maxPrice: 0,
			name: "",
		},
		resetPassword: {
			account: "",
		},
		classForm: {
			courseId: 0,
			color: null,
			isExperience: undefined,
			name: "",
			cover: "",
			introVideo: "",
			introduction: "",
			detail: "",
			status: "",
			instrumentId: 0,
			teacherId: 0,
			price: 0,
			priceRemark: "",
			degree: "",
			payPeriod: "",
			payMethod: [],
			payDiscount: [],
			teachingTypes: [],
			regions: [],
			isAddNewClass: true,
			duration: 0,
			maxStudentCount: 0,
			dateRange: [],
			courseOpenedTimeInfo: {
				startTime: null,
				endTime: null,
				availableWeekList: [],
			},
			courseTimeEntities: [],
		},
		workItem: {
			data: null,
			node: null,
			task: null,
			isMiddleBarShow: true,
			isMainBarShow: true,
			tableData: [],
			studentOptions: [],
		},
	},
	getters: {
		mobileSize(state) {
			return state.fullWidth < 768 ? true : false;
		},
		el_mobileSize(state) {
			return state.fullWidth < 1200 ? true : false;
		},
		isMessageShow(state) {
			return state.isMessageShow;
		},
		CreateGroupName(state) {
			return state.CreateGroupName;
		},
	},
	mutations: {
		setIsLoading(state, show) {
			state.isloading = show;
		},
		OpenMessageWin(state) {
			state.isMessageShow = true;
		},
		CloseMessageWin(state) {
			state.isMessageShow = false;
		},
		CreateMessageGroup(state, targetId) {
			state.CreateGroupName = targetId;
		},
	},
	actions: {},
});
